// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backend-index-js": () => import("./../../../src/pages/backend/index.js" /* webpackChunkName: "component---src-pages-backend-index-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-en-privacy-js": () => import("./../../../src/pages/en/privacy.js" /* webpackChunkName: "component---src-pages-en-privacy-js" */),
  "component---src-pages-frontend-index-js": () => import("./../../../src/pages/frontend/index.js" /* webpackChunkName: "component---src-pages-frontend-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-privacy-js": () => import("./../../../src/pages/pl/privacy.js" /* webpackChunkName: "component---src-pages-pl-privacy-js" */),
  "component---src-pages-unity-index-js": () => import("./../../../src/pages/unity/index.js" /* webpackChunkName: "component---src-pages-unity-index-js" */)
}

